export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_POST = 'FETCH_POST';
export const LIKE = 'LIKE';
export const COMMENT = 'COMMENT';
export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';
export const FETCH_BY_CREATOR = 'FETCH_BY_CREATOR';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const ADMINAUTH = 'ADMINAUTH';

//creating temp newsPost exclusive actions 
export const CREATE_NEWSPOST = 'CREATE';
export const DELETE_NEWSPOST = 'DELETE';
export const FETCH_ALL_NEWSPOSTS = 'FETCH_ALL_NEWSPOSTS';
export const FETCH_NEWSPOST = 'FETCH_NEWSPOST';