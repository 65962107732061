import axios from 'axios';
import * as dotenv from 'dotenv';


dotenv.config({path: '../../.env'});

//config = production points to render service
const url = (process.env.NODE_ENV=== "development") ? 
        process.env.DATABASE_SERVER_ADDRESS || 'https://34.125.227.140:27017' : 
        process.env.DATABASE_SERVER_ADDRESS || 'https://fieldbendersweb-backend-router.onrender.com';

const API = axios.create({ baseURL: url });

if(process.env.DATABASE_SERVER_ADDRESS) console.log(process.env.DATABASE_SERVER_ADDRESS);
console.log(axios.prototype)

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }


  return req;
});

export const fetchPost = (id) => API.get(`/posts/${id}`);
export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const fetchPostsByCreator = (name) => API.get(`/posts/creator?name=${name}`);
export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
export const createPost = (newPost) => API.post('/posts', newPost);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const comment = (value, id) => API.post(`/posts/${id}/commentPost`, { value });
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);

export const signAdminIn = (formData) => API.post('/admin/signin', formData);

export const fetchNewsPost = (id) => API.get(`/news/${id}`);
export const fetchNewsPosts = (page) => API.get(`/news?page=${page}`);
export const createNewsPost = (newNewsPost) => API.post('/news', newNewsPost);
export const deleteNewsPost = (id) => API.delete(`/news/${id}`);

